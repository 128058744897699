<template>
  <div class="index">
    <div class="index-title">合同详情</div>
    <Loading
      class="index-loading"
      v-if="current != total && showLoading"
      color="#3276ff"
      vertical
      >合同加载中...</Loading
    >
    <div class="index-list">
      <div class="index-pdf" v-for="i in numPages" :key="i">
        <PDF
          :src="src"
          :page="i"
          @num-pages="total = $event"
          @page-loaded="current = $event"
        ></PDF>
        <div class="unable" v-if="isEnabel==0">已失效</div>
      </div>
    </div>
    <div class="index-btns">
      <router-link
        class="go-pay"
        v-if="isPayEnd!=1&&canPay"
        :to="{path:'/pay-list',query:{contractId:contractId}}"
        >立即支付</router-link
      >
      <img
        src="../assets/images/sign-btn.svg"
        v-if="canSign"
        alt=""
        @click="showSign = true"
      />
    </div>
    <Popup
      v-model="hasSign"
      round
      lock-scroll
      lazy-render
      :close-on-click-overlay="false"
      :overlay-style="{ opacity: 0.4 }"
      class="success-pop"
    >
      <div class="index-success">
        <img src="../assets/images/success.svg" alt="" />
        <span>签署成功</span>
      </div>
    </Popup>
    <Sign :show.sync="showSign" @confirm="handleCompleteSign"></Sign>
    <PDF></PDF>
  </div>
</template>

<script>
import PDF from "vue-pdf";
import { Loading, Popup, Toast } from "vant";
import Sign from "@/components/Sign";
export default {
  name: "index",
  components: { PDF, Loading, Popup, Sign },
  data() {
    return {
      contractId: this.$route.query.contractId,
      src: "",
      numPages: "",
      current: 1,
      total: 0,
      hasSign: false,
      showSign: false,
      canPay: false,
      canSign:false,
      showLoading:false,
      isPayEnd:1,
      isEnabel:1
    };
  },
  created() {
    if (
      !this.$route.query.contractId ||
      this.$route.query.contractId == "undefined"
    ) {
      Toast({
        message: "缺少合同编号",
        icon: "warning",
        duration: 2000,
      });
      return false;
    }
    this.handleGetContract()
  },
  mounted() {},
  methods: {
    handleGetContract(){
      this.$http.get("/front/onlineContract/findInitPDF",{contractCode:this.$route.query.contractId}).then((res) => {
        if (res.code==1) {
          this.showLoading=true
          this.handleLoadPDF(res.data.pdfPath)
          this.canSign=(res.data.isSign==0)
          this.canPay=(res.data.isSign==1&&res.data.isPayEnd==0)
          this.isPayEnd=res.data.isPayEnd
          this.isEnabel=res.data.isEnabel
        }else{
          Toast({
              icon: "clear",
              forbidClick:true,
              message: res.message,
              duration: 2000,
            });
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    async handleLoadPDF(url) {
      this.src = await PDF.createLoadingTask(url);
      this.src.promise.then((res) => {
        this.numPages = res.numPages;
      });
    },
    handleClose() {
      this.showSign = false;
    },
     handleCompleteSign({data}) {
      this.showSign = false;
      this.hasSign = true;
      this.canSign=false
      this.canPay=true
      setTimeout(() => {
        this.hasSign = false;
        this.handleLoadPDF(data.url)
      }, 1500);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='less'>
.index {
  width: 100%;
  min-height: 100%;
  padding: 0 12px;
  background: #f3f4f7;
  background-image: url("../assets/images/contract-bg.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  .success-pop {
    transform: translate(-50%, -70%);
  }
  &-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 73px;
    img {
      display: block;
      width: 64px;
      height: 64px;
      margin-bottom: 12px;
    }
    span {
      display: block;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      white-space: nowrap;
    }
  }
  &-title {
    text-align: center;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    padding: 25px 0;
  }
  &-list {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
  &-pdf {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 9px;
    background: #ffffff;
    position: relative;
    &:first-of-type {
      span {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
      }
    }
    &:last-of-type {
      span {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
      }
    }
    .unable{
      width: 100%;
      color: rgba(152, 152, 158, 0.25);
      font-size: 50px;
      position: absolute;
      top: 40%;
      left: 0;
      text-align: center;
      transform: rotateZ(30deg);
      font-weight: bold;
      letter-spacing: 2px;
    }
  }
  &-loading {
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }
  &-btns {
    position: fixed;
    bottom: 34px;
    left: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    .go-pay {
      width: 134px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      background: #3276ff;
      box-shadow: 0px 2px 5px 0px rgba(121, 147, 197, 0.6);
      border-radius: 28px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    img {
      display: block;
      width: 52px;
      height: 52px;
      border-radius: 52px;
      background: #3276ff;
      box-shadow: 0px 2px 5px 0px rgba(121, 147, 197, 0.6);
    }
  }
}
</style>
